<template>
  <Page title="Billing Accounts - Manage">
    <BillingAccounts />
  </Page>
</template>
<script>
import BillingAccounts from "@/components/accounts/BillingAccounts";
import Page from "@/components/layout/Page";

export default {
  name: "ManageBillingAccounts",
  components: { Page, BillingAccounts }
};
</script>
