<template>
  <div>
    <LoadingBar v-if="loading === true" />
    <template v-else>
      <v-data-table
        :headers="headers"
        :items="accounts"
        no-data-text="You have no billing accounts to manage."
        :footer-props="{
          itemsPerPageText: 'Rows count',
          itemsPerPageAllText: 'All'
        }"
      >
        <template v-slot:item.approved="{ item }">
          <div v-if="item.approved">
            Approved
          </div>
          <div v-else>
            Waiting for Approval
          </div>
        </template>
        <template v-slot:item.actions="{ item, index }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <template v-if="!item.approved">
                <v-icon
                  v-on="on"
                  medium
                  color="success"
                  @click="
                    updateApproved({ index: index, item, approved: true })
                  "
                >
                  mdi-check-circle-outline
                </v-icon>
              </template>
              <template v-else-if="item.approved">
                <v-icon
                  v-on="on"
                  medium
                  color="error"
                  @click="
                    updateApproved({ index: index, item, approved: false })
                  "
                >
                  mdi-alpha-x-circle-outline
                </v-icon>
              </template>
            </template>
            <span v-if="item.approved">Disapprove</span>
            <span v-else-if="!item.approved">Approve</span>
          </v-tooltip>
        </template>
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength }}
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";

export default {
  name: "BillingAccounts",
  components: {
    LoadingBar
  },
  data() {
    return {
      loading: false,
      loadingApproved: {},
      singleSelect: false,
      headers: [
        {
          text: "Billing Account",
          value: "client_third_party.title"
        },
        {
          text: "Status",
          value: "approved"
        },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapFields("billingAccounts", ["accounts", "approve_billing_selected"])
  },
  methods: {
    ...mapActions("billingAccounts", [
      "getThirdPartyBillingClients",
      "updateApprovedModule"
    ]),
    updateApproved(itemToUpdate) {
      this.updateApprovedModule(itemToUpdate);
    }
  },
  mounted() {
    this.loading = true;
    this.getThirdPartyBillingClients().then(() => (this.loading = false));
  }
};
</script>

<style scoped></style>
